<template>
  <div class="flex justify-center">
    <div class="numbers-wrapper flex flex-col">
      <div class="text-lg font-nova-semibold">{{ label.toUpperCase() }}</div>
      <div class="numbers-container text-white">
        <div
          v-for="value in 10"
          :key="value"
          class="number relative cursor-pointer"
          :style="`backgroundColor: ${bg(value)}`"
          @click="$emit('setSelected', value)"
        >
          <div class="font-nova-black">
            {{ value }}
          </div>

          <div v-if="selected && selected !== value" class="white-overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: {
    label: { type: String, default: '' },
    selected: { type: Number, default: null }
  },
  methods: {
    bg(value) {
      return value > 6 ? (value > 8 ? 'green' : 'orange') : 'red'
    }
  }
}
</script>

<style scoped>
.numbers-wrapper {
  width: 100%;
  /* width: 16rem; */
}

.numbers-container {
  display: grid;
  grid-gap: 1px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.number {
  @apply flex items-center justify-center text-3xl font-bold;
  aspect-ratio: 1;
}

.white-overlay {
  @apply absolute top-0 w-full h-full;
  background: rgba(255, 255, 255, 0.8);
}
</style>