<template>
  <div class="flex flex-col gap-1">
    <BackButton class="mb-1" :route="{ name: 'Director' }" />

    <SeleccionRecurso
      :recurso="recurso"
      store="evaluacionSemanal"
      :reset-state-on-menu-open="true"
      class="mb-1"
    />
    <div v-if="recurso" class="flex flex-col gap-2">
      <!-- <div class="p-4 bg-white">
        <h2 class="score-group-title">33% COMPROMISO CON TELETRABAJO</h2>
        <Score
          label="disponible"
          class="mb-4"
          :selected="disponible"
          @setSelected="setDisponible"
        />
        <Score label="cumplimiento" :selected="cumplimiento" @setSelected="setCumplimiento" />
      </div> -->

      <div class="p-4 bg-white">
        <h2 class="score-group-title">50% CALIDAD DE TRABAJO</h2>
        <Score
          label="autosuficiente"
          class="mb-4"
          :selected="autosuficiente"
          @setSelected="setAutosuficiente"
        />
        <Score label="bienHecho" :selected="bienHecho" @setSelected="setBienHecho" />
      </div>

      <div class="p-4 bg-white">
        <h2 class="score-group-title">50% DESEMPEÑO REAL</h2>
        <Score label="aTiempo" class="mb-4" :selected="aTiempo" @setSelected="setATiempo" />
        <Score label="eficiencia" :selected="eficiencia" @setSelected="setEficiencia" />
      </div>
    </div>

    <SubmitButton
      text="ingresar"
      :disabled="!formIsFilled || loading"
      @onClick="submitEvaluacionSemanal"
    />

    <BlackModal
      v-if="showSuccessModal"
      text="Evaluación semanal enviada con éxito"
      @confirm="finalize"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BlackModal from '@/components/shared/BlackModal.vue'
import BackButton from '@/components/shared/BackButton.vue'
import SubmitButton from '@/components/shared/SubmitButton.vue'
import SeleccionRecurso from '@/components/director/SeleccionRecurso.vue'
import Score from '@/components/director/evaluacion-semanal/Score.vue'

export default {
  name: 'EvaluacionSemanal',
  components: {
    BlackModal,
    BackButton,
    SubmitButton,
    SeleccionRecurso,
    Score
  },
  data() {
    return {
      showSuccessModal: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      recurso: 'evaluacionSemanal/recurso',
      disponible: 'evaluacionSemanal/disponible',
      cumplimiento: 'evaluacionSemanal/cumplimiento',
      autosuficiente: 'evaluacionSemanal/autosuficiente',
      bienHecho: 'evaluacionSemanal/bienHecho',
      aTiempo: 'evaluacionSemanal/aTiempo',
      eficiencia: 'evaluacionSemanal/eficiencia'
    }),

    formIsFilled() {
      return (
        this.recurso &&
        this.autosuficiente &&
        this.bienHecho &&
        this.aTiempo &&
        this.eficiencia
      )
    }
  },

  methods: {
    ...mapActions({ addEvaluation: 'evaluations/add' }),

    ...mapMutations({
      setRecurso: 'evaluacionSemanal/setRecurso',
      setDisponible: 'evaluacionSemanal/setDisponible',
      setCumplimiento: 'evaluacionSemanal/setCumplimiento',
      setAutosuficiente: 'evaluacionSemanal/setAutosuficiente',
      setBienHecho: 'evaluacionSemanal/setBienHecho',
      setATiempo: 'evaluacionSemanal/setATiempo',
      setEficiencia: 'evaluacionSemanal/setEficiencia'
    }),

    async submitEvaluacionSemanal() {
      this.loading = true

      const { success } = await this.addEvaluation({
        empleado: this.recurso.id,
        autosuficiente: this.autosuficiente,
        bienHecho: this.bienHecho,
        aTiempo: this.aTiempo,
        eficiencia: this.eficiencia
      })

      if (success) {
        this.showSuccessModal = true
      }

      this.loading = false
    },

    finalize() {
      this.cleanState()
      this.$router.push({ name: 'Director' })
    },

    cleanState() {
      this.setRecurso(null)
      this.setDisponible(null)
      this.setCumplimiento(null)
      this.setAutosuficiente(null)
      this.setBienHecho(null)
      this.setATiempo(null)
      this.setEficiencia(null)
    }
  }
}
</script>

<style scoped>
.score-group-title {
  @apply mb-5;
  font-family: ProximaNovaBlack;
  line-height: 1;
  font-size: 1.75rem;
}
</style>
